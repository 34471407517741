import { PDFDocument } from "pdf-lib"

const Uint8ToString = u8a => {
  var CHUNK_SZ = 0x8000
  var c = []
  for (var i = 0; i < u8a.length; i += CHUNK_SZ) {
    c.push(String.fromCharCode.apply(null, u8a.subarray(i, i + CHUNK_SZ)))
  }
  return c.join("")
}

const fileToBase64 = async file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      resolve(reader.result)
    }
    reader.onerror = function (error) {
      console.log(error)
      reject()
    }
  })
}

const buildCustomPDF = async (originalPDF, checkboxData) => {
  const originalDocBytes = await fetch(originalPDF).then(res => res.arrayBuffer())
  const originalDoc = await PDFDocument.load(originalDocBytes, { updateMetadata: false })
  const numberOfPages = originalDoc.getPages().length
  const metadata = JSON.parse(originalDoc.getKeywords())
  const optionalPages = []

  metadata.forEach(m => {
    for (let key in m) {
      optionalPages.push(...m[key].flat())
    }
  })

  const pdfDoc = await PDFDocument.create()

  const toProcess = []

  for (let cbd of checkboxData) {
    metadata.forEach(m => {
      for (let key in m) {
        if (cbd === key) {
          toProcess.push(...m[key])
        }
      }
    })
  }

  if (!toProcess.length) {
    // make them check something here if you want
  }

  for (let i = 0; i < numberOfPages; i++) {
    if (optionalPages.includes(i)) {
      if (toProcess.includes(i)) {
        let [j] = await pdfDoc.copyPages(originalDoc, [i])
        pdfDoc.addPage(j)
      }
    } else {
      let [j] = await pdfDoc.copyPages(originalDoc, [i])
      pdfDoc.addPage(j)
    }
  }

  const pdfBytes = await pdfDoc.save()
  return pdfBytes
}

const randomId = (length) => {
  var result = ""
  var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
  var charactersLength = characters.length
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export { Uint8ToString, fileToBase64, buildCustomPDF, randomId }
