import * as React from "react"
import { Routes, Route } from "react-router-dom"
import Home from "./containers/Home"
import Builder from './containers/Builder'
import routes from "./routes"

function App() {
  return (
    <Routes>
      <Route path="/" index element={<Home routes={routes} />} />
      {routes.map(({ endpoint, ...rest }) => (
        <Route key={endpoint} path={`${endpoint}/*`} element={<Builder {...rest} />} />
      ))}
      <Route path="*" element={<Home routes={routes} />} />
    </Routes>
  )
}

export default App
