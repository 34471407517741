import * as React from "react"
import { Modal } from "bootstrap"
import { PDFDocument } from "pdf-lib"
import download from "downloadjs"
import SVGIcon from "../components/SVGIcon"
import EmailModal from "../components/EmailModal"
import CheckboxList from "../components/CheckboxList"
import { fileToBase64, buildCustomPDF, randomId } from "../utils"
import getTheDetails from "../img/get-the-details-h.png"
import "../styles/containers/Builder.scss"

const url = `http${process.env.NODE_ENV === "production" ? "s" : ""}://brookfield-brochure-builder-email.${
  process.env.NODE_ENV === "production" ? "gjstage.com" : "test"
}/index.php`

function App({ title, subtitle, sqft, beds, baths, garage, options, image, pdf, filename }) {
  const modalRef = React.useRef()
  const [loadingPrint, setLoadingPrint] = React.useState(false)
  const [loadingEmail, setLoadingEmail] = React.useState(false)
  const [showCheckEmail, setShowCheckEmail] = React.useState(false)
  const [checkboxes, setCheckboxes] = React.useState([])
  const [checkboxData, setCheckboxData] = React.useState([])

  React.useEffect(() => {
    async function init() {
      const originalDocBytes = await fetch(pdf).then(res => res.arrayBuffer())
      const originalDoc = await PDFDocument.load(originalDocBytes, { updateMetadata: false })
      const metadata = JSON.parse(originalDoc.getKeywords())

      if (metadata && metadata.length) {
        setCheckboxes(Object.keys(metadata[0]))
        setCheckboxData([])
      }
    }
    init()
  }, [pdf])

  function updateCheckboxData(value) {
    let copy = checkboxData.slice()
    let index = copy.findIndex(el => el === value)
    if (index === -1) {
      copy.push(value)
    } else {
      copy.splice(index, 1)
    }
    setCheckboxData(copy)
  }

  async function createBase64() {
    const pdfBytes = await buildCustomPDF(pdf, checkboxData)
    const base64 = await fileToBase64(new Blob([new Uint8Array(pdfBytes).buffer], { type: "application/pdf" }))
    return base64
  }

  async function emailBrochure(e, emailAddress) {
    e.preventDefault()

    setLoadingEmail(true)

    const base64 = await createBase64()
    const tmp = randomId(5)
    const data = `filename=${filename}&emailAddress=${emailAddress}&tmp=${tmp}&attachment=${base64}`

    await fetch(url, {
      method: "POST",
      mode: "no-cors",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: data,
    })

    setLoadingEmail(false)
    setShowCheckEmail(true)
  }

  async function printBrochure() {
    setLoadingPrint(true)
    const pdfBytes = await buildCustomPDF(pdf, checkboxData)
    download(pdfBytes, `${filename.replace(/\s/g, "-")}.pdf`, "application/pdf")
    setLoadingPrint(false)
  }

  function openModal() {
    const modalEle = modalRef.current
    const bsModal = new Modal(modalEle)
    bsModal.show()
  }

  function closeModal() {
    const modalEle = modalRef.current
    const bsModal = Modal.getInstance(modalEle)
    bsModal.hide()
    setShowCheckEmail(false)
  }

  return (
    <div id="Builder">
      <main>
        <div className="container">
          <div className="get-the-details">
            <img className="img-fluid" src={getTheDetails} alt="Get The Details" width={800} height={174} />
          </div>
          <div className="broadway-exterior">
            <img className="img-fluid" src={image} alt="Exterior Broadway" width={1600} height={1100} />
          </div>
          <h1>{title}</h1>
          <p className="subtitle">{subtitle}</p>
          <div className="specifications">
            <div className="line-item sq">
              <SVGIcon name="sign" className="sign-icon" />
              <span>{sqft}</span>
            </div>
            <div className="specs">
              <div className="line-item">
                <SVGIcon name="bed" className="bed-icon" />
                <span>{beds}</span>
              </div>
              <div className="line-item">
                <SVGIcon name="bath" className="bath-icon" />
                <span>{baths}</span>
              </div>
              <div className="line-item">
                <SVGIcon name="garage" className="garage-icon" />
                <span>{garage}</span>
              </div>
            </div>
            {options.map(option => (
              <div key={option} className="line-item option">
                <span>{option}</span>
              </div>
            ))}
          </div>
          <div className="instructions">Check the sections you wish to include</div>
          <CheckboxList checkboxes={checkboxes} updateCheckboxData={updateCheckboxData} />
          <div className="buttons">
            <button type="button" className="btn" disabled={loadingPrint || loadingEmail} onClick={printBrochure}>
              {loadingPrint ? (
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              ) : (
                <span>Download Brochure</span>
              )}
            </button>
            <button type="button" className="btn" disabled={loadingPrint || loadingEmail} onClick={openModal}>
              <span>Email Brochure</span>
            </button>
          </div>
        </div>
      </main>

      <EmailModal
        modalRef={modalRef}
        loadingEmail={loadingEmail}
        showCheckEmail={showCheckEmail}
        filename={filename}
        emailBrochure={emailBrochure}
        closeModal={closeModal}
      />
    </div>
  )
}

export default App
