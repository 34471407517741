function Checkbox({ label, value, updateCheckboxData }) {
  return (
    <label className="list-group-item">
      <input className="form-check-input" type="checkbox" value={value} onChange={() => updateCheckboxData(value)} />
      <span>{label}</span>
    </label>
  )
}

export default Checkbox
