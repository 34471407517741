import Checkbox from "./Checkbox"
import "../styles/components/CheckboxList.scss"

function CheckboxList({ checkboxes, updateCheckboxData }) {
  return (
    <div id="CheckboxList" className="list-group">
      {checkboxes.map(checkbox => (
        <Checkbox
          key={checkbox}
          label={checkbox}
          value={checkbox}
          updateCheckboxData={updateCheckboxData}
        />
      ))}
    </div>
  )
}

export default CheckboxList
