import * as React from "react"
import "../styles/components/EmailModal.scss"

function EmailModal({ modalRef, loadingEmail, showCheckEmail, filename, emailBrochure, closeModal }) {
  const [emailAddress, setEmailAddress] = React.useState("")

  function renderModalContent() {
    if (showCheckEmail) {
      return (
        <>
          <h5>Save Your Brochure</h5>
          <p>
            Check your email for
            <br />
            your {filename.replace('Brochure', 'brochure')}
          </p>
        </>
      )
    } else {
      return (
        <>
          <h5 className="line-1">Save Your Brochure</h5>
          <form onSubmit={e => emailBrochure(e, emailAddress)}>
            <input
              type="email"
              className="form-control"
              placeholder="Email Address"
              autoComplete="email"
              required
              spellCheck={false}
              value={emailAddress}
              onChange={e => setEmailAddress(e.target.value)}
            />
            <button className="btn">
              {loadingEmail ? (
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              ) : (
                <span>Submit</span>
              )}
            </button>
          </form>
        </>
      )
    }
  }

  return (
    <div id="EmailModal" className="modal fade" ref={modalRef} tabIndex="-1">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <button type="button" className="btn-close" aria-label="Close" onClick={closeModal}></button>
          <div className={`modal-body${showCheckEmail ? " check-email" : ''}`}>{renderModalContent()}</div>
        </div>
      </div>
    </div>
  )
}

export default EmailModal
