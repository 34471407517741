import { Link } from "react-router-dom"
import brookfieldResidential from "../img/brookfield-residential.png"
import "../styles/containers/Home.scss"

function Home({ routes }) {
  return (
    <div id="home">
      <img src={brookfieldResidential} alt="Brookfield Residential" />
      {routes.map(route => (
        <Link key={route.endpoint} to={route.endpoint}>
          {route.title}
        </Link>
      ))}
    </div>
  )
}

export default Home
